import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout, Select } from "antd";
import appActions from "../../redux/app/actions";

import TopbarWrapper from "./topbar.style";
import themes from "../../settings/themes";
import { themeConfig } from "../../settings";
import TopbarUser from "./topbarUser";
import TopbarUserDetails from "./TopbarUserDetails";
import userpic from "../../image/avatar.png"

const { Header } = Layout;
const { toggleCollapsed, getSchools } = appActions;
const customizedTheme = themes[themeConfig.theme];
const Option = Select.Option;

class Topbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: "english",
      school: undefined,
    };
  }

  componentWillMount() {
    // this.props.getSchools();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.appLang) {
      this.setState({ lang: nextProps.appLang.languageId });
    }
    // if (this.props.schools !== nextProps.schools) {
    //   let school = undefined;
    //   if (localStorage.getItem("School")) {
    //     school = localStorage.getItem("School").replace(/['"]+/g, "");
    //   }
    //   this.setState({ school });
    // }
  }

  handleChangeLang = (lang) => {
    this.setState({ lang });
    const _this = this;
    this.props.changeLanguage(lang, this.props.user.id);
  };

  // handleChange = (val) => {
  //   this.setState({ school: val });
  //   if (val) {
  //     localStorage.setItem("School", JSON.stringify(val));
  //   } else {
  //     localStorage.removeItem("School");
  //   }
  //   window.location.reload();
  // };

  render() {
    const { toggleCollapsed, notifications, schools, role } = this.props;
    const user = JSON.parse(localStorage.getItem('userData'))
    const { lang, school } = this.state;
    const collapsed = this.props.collapsed && !this.props.openDrawer;
    const styling = {
      background: customizedTheme.backgroundColor,
      position: "fixed",
      width: "100%",
      height: 70,
    };
    return (
      <TopbarWrapper>
        <Header
          style={styling}
          className={
            collapsed ? "isomorphicTopbar collapsed" : "isomorphicTopbar"
          }
        >
          <div className="isoLeft">
            <TopbarUserDetails />
          </div>

          <ul className="isoRight" style={{ height: "40px" }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "10px" }}>
              <p style={{ marginBottom: "3px", marginRight: "20px" }}>
                <strong style={{ fontSize: "17px" }}>
                  Odin Admin Panel
                </strong>
              </p>
            </div>
            <li
              onClick={() => this.setState({ selectedItem: "notification" })}
              className="isoNotify"
            />
            <li
              onClick={() => this.setState({ selectedItem: "user" })}
              className="isoUser"
            >
              <TopbarUser />
            </li>
          </ul>
        </Header>
      </TopbarWrapper>
    );
  }
}

export default connect(
  (state) => {
    ({
      collapsed: state.App.collapsed,
      openDrawer: state.App.openDrawer,
      user: state.Auth.get("profile"),
      role: state.Auth.getIn(["profile"])['userType'],
      // schools: state.App.schools,
    })
  },
  { toggleCollapsed }
)(Topbar);
